/* Start variables */
:root {
  --main_color: #605485;
  --main_font: "Poppins", sans-serif;
}
/* End variables */
/* Start Global Rules */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: black;
}
ol {
  list-style: none;
}
body {
  font-family: var(--main_font);
  overflow-x: hidden;
}
/* ::-webkit-scrollbar {
  width: 1rem;
} */

/* ::-webkit-scrollbar-track {
  background-color: var(--second-color);
  }

::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--black-color);
} */
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
/* Small */
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
/* Medium */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
/* Large */
@media (min-width: 1200px) {
  .container {
    width: 1000px;
  }
}
/* End Global Rules */
