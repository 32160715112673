.dashboard main {
  text-transform: capitalize;
}
.dashboard main.showInformation {
  width: 100%;
  max-width: 700px;
}
.dashboard main.showInformation .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.dashboard main.showInformation .top h1,
.dashboard main.showInformation .top h4 {
  margin: 0;
  padding: 0;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.dashboard main.showInformation .top h4 {
  font-size: 15px;
  font-weight: 600;
  color: #e39f22;
}
.dashboard main.showInformation .informationCard {
  margin: 0 0 20px;
  width: 100%;
  border: 1px solid #3333;
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
}
.dashboard main.showInformation .informationCard .title {
  padding: 15px 25px;
  border-bottom: 1px solid #3333;
  background-color: #f0f0f0;
  text-transform: capitalize;
  font-size: 17px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: #5b5454;
}
.dashboard main.showInformation .informationCard .title i {
  font-size: 16px;
  margin-right: 10px;
}
.dashboard main.showInformation .informationCard table {
  padding: 0 25px;
}
.dashboard main.showInformation .informationCard table th {
  padding: 12px 0px;
  color: #555;
  border-bottom: 1px solid #3333;
  font-weight: 500;
}
.dashboard main.showInformation .informationCard table tr:last-child th {
  border: none;
}
.dashboard main.showInformation .informationCard table th:last-child {
  padding-left: 20px;
  font-weight: 700;
}
