.loginPage {
  background-color: var(--main_color);
  min-height: 100vh;
  padding: 1px 0;
}
.loginPage .container {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 30px;
  min-height: calc(100vh - 60px);
  position: relative;
}
.loginPage form {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 45%;
  left: 50%;
}
.loginPage h1 {
  text-align: center;
  font-size: 24px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 600;
}
.loginPage hr {
  max-width: 36px;
  border: none;
  height: 4px;
  border-radius: 2px;
  background-color: var(--main_color);
  margin: -7px auto 19px;
}
.loginPage input[type="text"],
.loginPage input[type="password"] {
  outline: none;
  display: block;
  width: 250px;
  max-width: 100%;
  padding: 14px 10px 6px;
  border: none;
  border-bottom: 1px solid #3333;
  margin: 14px auto 6px;
  letter-spacing: 0.7px;
}
.loginPage input:nth-child(2) {
  margin-bottom: 24px;
}
.loginPage input[type="text"]:focus,
.loginPage input[type="password"]:focus {
  border-color: var(--main_color);
}
.loginPage p.error {
  font-size: 13px;
  letter-spacing: 0.3px;
  color: red;
  padding: 3px 10px;
  font-weight: 500;
}
.loginPage .showPassword {
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: center;
  padding: 10px 0 0;
}
.loginPage input[type="checkbox"] {
  margin-right: 7px;
}
.loginPage button {
  display: block;
  padding: 10px 15px;
  min-width: 250px;
  max-width: 100%;
  margin: 20px auto 0px;
  border: none;
  background-color: var(--main_color);
  color: white;
  letter-spacing: 0.9px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  opacity: 0.85;
}
.loginPage button:hover {
  opacity: 1;
}
