header {
  box-shadow: 0 0 5px #00000026;
}
header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .content .icon {
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: none;
  cursor: pointer;
  margin-right: 10px;
}
header .content .icon span {
  display: flex;
  height: 2px;
  width: 100%;
  background-color: var(--main_color);
}
header .content .logo h1 {
  margin: 3px 0;
}
header .content .logo h1 a {
  letter-spacing: 1px;
  color: var(--main_color);
  padding: 20px;
  display: block;
  text-decoration: none;
}

header .content .menu #close {
  display: none;
  color: white;
  font-size: 40px;
  position: absolute;
  right: 0;
  padding: 9px 19px;
}
header .content .menu h1 {
  display: none;
}
header .content .menu hr {
  display: none;
}
header .content .menu ol {
  list-style-type: none;
}
header .content .menu ol li {
  display: inline-block;
}
header .content .menu ol li a,
main .content .description button a,
.gt_y_prgm .parent .content button a {
  padding: 11px 10px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  display: block;
}
header .content .menu ol li a:hover {
  color: var(--main_color);
}
header .content .menu ol li:last-child a,
main .content .description button a,
.gt_y_prgm .parent .content button a {
  color: var(--main_color);
  background-color: white;
  margin-left: 20px;
  border: none;
}
header .content .menu ol li:last-child a {
  border: 1px solid var(--main_color);
}
header .content .menu ol li:last-child a:hover {
  background-color: var(--main_color);
  color: white;
}
/* Small */
@media (max-width: 768px) {
  header .content .icon {
    display: flex;
  }
  header .content .menu {
    /* display: none; */
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: black;
    z-index: 1;
  }
  header .content .menu #close {
    display: block;
    cursor: pointer;
  }
  header .content .menu h1 {
    display: block;
    font-weight: bold;
    color: white;
    padding: 30px 0px;
    margin: 44px 0 0;
    text-align: center;
    letter-spacing: 2.2px;
    font-size: 35px;
  }
  header .content .menu hr {
    display: block;
    border: none;
    height: 5px;
    border-radius: 3px;
    width: 200px;
    margin: 0 auto;
    background-color: var(--main_color);
  }
  header .content .menu ol {
    margin-top: 37px;
  }
  header .content .menu ol li {
    display: block;
  }
  header .content .menu ol li a {
    background-color: transparent !important;
    border: none !important;
    margin: 1px 0 !important;
    color: rgb(255, 255, 255) !important;
    padding: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #ffffff1c !important;
  }
  header .content .menu ol li a:hover {
    background-color: var(--main_color) !important;
    color: rgb(255, 255, 255);
    opacity: 0.9;
  }
  header .content .menu ol li:last-child a {
    background-color: var(--main_color) !important;
  }
}
