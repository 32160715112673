.endPoint .container {
  padding: 0;
  min-height: auto;
}
.endPoint .icon {
  padding: 30px 20px;
  background-color: #87bd48;
}
.endPoint .icon i {
  font-size: 60px;
  margin-bottom: 13px;
  color: white;
}
.endPoint .icon p {
  font-weight: 500;
  color: white;
  opacity: 1;
}
.endPoint .bottom {
  padding: 20px;
}
.endPoint .bottom p {
  margin-top: 20px;
}
.endPoint .bottom p:first-child {
  margin-top: 0;
}
.endPoint button {
  width: 339px;
  max-width: 70%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: 2px solid #6baf1c;
  background-color: #6baf1c;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.3s;
}
.endPoint button:hover {
  opacity: 1;
}
.endPoint button a {
  width: 100%;
  color: white;
  padding: 10px;
  display: block;
}
