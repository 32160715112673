.getprogram {
  width: 100%;
  min-height: 100vh;
  background-color: #716b83a6;
  padding-top: 40px;
  position: relative;
}
.getprogram.full-screen {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  padding-top: 0;
}
.getprogram .container {
  position: relative;
  width: 600px;
  min-height: 500px;
  max-width: 95%;
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-shadow: 0 0 20px 0 #0000002b;
  padding: 40px 60px 105px;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  background-color: white;
}
.getprogram.full-screen .container {
  position: relative;
  min-height: auto;
  max-width: auto;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0px;
  overflow: hidden;
  text-align: start;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
/* Small */
@media (min-width: 768px) {
  .getprogram.full-screen .container {
    width: 750px;
  }
}
/* Medium */
@media (min-width: 992px) {
  .getprogram.full-screen .container {
    width: 970px;
  }
}
/* Large */
@media (min-width: 1200px) {
  .getprogram.full-screen .container {
    width: 1000px;
  }
}
/* End Globa*/
.getprogram .container hr.top {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  width: 50%;
  height: 7px;
  background-color: var(--main_color);
}
.getprogram .container .createProgram {
  margin: 18px 0 15px;
  text-align: center;
  font-size: 15px;
  word-spacing: 3px;
  font-weight: 600;
  color: #333;
}
.getprogram .container .createProgram i {
  color: rgb(95, 160, 221);
  transform: translateY(2px);
  font-size: 17px;
  animation: creatingProAnim 2s infinite linear;
}
@keyframes creatingProAnim {
  50% {
    transform: translateY(2px) rotate(180deg);
  }
  100% {
    transform: translateY(2px) rotate(360deg);
  }
}
.getprogram .container h2 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-bottom: 6px;
  line-height: 1.5;
}
.getprogram .container p {
  margin: 0 auto;
  letter-spacing: 1px;
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 3px;
  line-height: 1.7;
}
.getprogram .container label p {
  line-height: 1.3;
  font-weight: 500;
  font-size: 13px;
}
.getprogram .container input {
  margin: 30px 0 10px;
  padding: 18px 15px;
  font-size: 16px;
  width: 100%;
  outline: none;
  letter-spacing: 1.4px;
  border: 1px solid #3333;
  border-radius: 8px;
}
.getprogram .container input[type="radio"] {
  display: none;
}
.getprogram .container input.smallInput {
  width: fit-content;
  font-size: 14px;
  letter-spacing: 0.4px;
  max-width: 40%;
}
.getprogram .container label {
  display: block;
  width: 80%;
  padding: 11px;
  border: 3px solid #3333;
  border-radius: 3px;
  margin: 7px auto;
  color: #333;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
}
.getprogram .container label.smallLabel {
  width: max-content;
  font-size: 14px;
  padding: 5px 6px;
  border-color: #ababab;
  border-width: 1px;
  font-weight: 300;
  color: #878787;
  display: inline-block;
  margin-left: 9px;
}
.getprogram .container label:hover,
.getprogram .container label.active {
  font-weight: 700;
  border-color: var(--main_color);
  color: var(--main_color);
}
.getprogram.full-screen ul li {
  margin-top: 10px;
}
.getprogram .container p.error {
  color: rgb(178, 12, 2);
  padding: 0 10px;
}
.getprogram .container .buttons {
  position: absolute;
  width: calc(100% - 80px);
  left: 40px;
  bottom: 15px;
}
.getprogram.full-screen .container .buttons {
  position: static;
  margin-top: 30px;
  width: 100%;
}
.getprogram .container .buttons button {
  width: 48%;
  padding: 15px;
  border: 4px solid var(--main_color);
  font-weight: bold;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.95;
}
.getprogram .container .paymentButton {
  height: 50px;
  width: 300px;
  max-width: 100%;
  margin: 5px auto;
  display: block;
  cursor: pointer;
  background-color: #fff9f9;
  border: 2px solid #3333;
  border-radius: 3px;
  transition: 0.3s;
}
.getprogram .container .paymentButton:hover {
  border-color: var(--main_color);
}
.getprogram .container button img {
  max-width: 30%;
  max-height: 100%;
}
.getprogram .container button:hover {
  opacity: 1;
}
.getprogram .container .backbutton {
  background-color: transparent;
  color: var(--main_color);
  float: left;
}
.getprogram .container .nextbutton {
  background-color: var(--main_color);
  color: white;
  float: right;
}
.getprogram .container .creatingBMI {
  margin-top: 75px;
  font-weight: bold;
  color: rgb(7, 110, 207);
  animation: creatingBMIAn 0.3s infinite linear;
}
.getprogram .container h3.creatingBMI {
  color: #333;
  font-weight: 600;
}
.getprogram .container h3.creatingBMI i {
  color: rgb(7, 110, 207);
}
@keyframes creatingBMIAn {
  50% {
    transform: rotate(3deg);
  }
  100%,
  0% {
    transform: rotate(-3deg);
  }
}
