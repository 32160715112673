/* start main */
main {
  margin-top: 60px;
}
main h2 {
  display: none;
}
main .content {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: nowrap;
}
main .content .description {
  max-width: 450px;
}
main .content .description h1,
main h2 {
  font-size: 65px;
  letter-spacing: 1px;
  margin: 70px 0 20px;
}
main .content .description p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-bottom: 9px;
}
main .content .description button a,
.gt_y_prgm .parent .content button a {
  margin: 0;
  background-color: var(--main_color);
  color: white;
}
main .content .description button:hover,
.gt_y_prgm .parent .content button:hover {
  transform: translate(5px);
}
main .content .description button,
.gt_y_prgm .parent .content button {
  width: 100%;
  margin-top: 15px;
  border: none;
}
@media (max-width: 768px) {
  main {
    margin-top: 0;
  }
  main h2 {
    display: block;
    font-size: 36px;
    line-height: 1.6;
    margin-top: 15px;
  }
  main .content {
    flex-wrap: wrap;
  }
  main .content .description {
    order: 2;
    min-width: 100%;
  }
  main .content .description h1 {
    display: none;
  }
  main .content .image {
    order: 1;
    width: 100%;
    margin: 0 0 20px;
    padding: 20px;
    border: 1px solid #5553;
  }
}
/* end main  */
/* start tools  */
.tools {
  margin-top: 100px;
}
.tools .content {
  text-align: center;
}
.tools .content h1,
.stories .content .title h1 {
  font-size: 39px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0;
}
.tools .content hr,
.stories .content .title hr {
  border: none;
  height: 5px;
  max-width: 200px;
  background-color: var(--main_color);
  margin: 15px auto 19px;
  border-radius: 2px;
}
.tools .content p {
  letter-spacing: 1px;
  font-size: 15px;
  word-spacing: 1px;
}
.tools .content .section,
.stories .content .cont {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.tools .content .section .con {
  width: 30%;
  min-width: 300px;
}
.tools .content .section .con .icon img {
  margin: 30px 0;
  padding: 19px;
  border-radius: 50%;
  box-shadow: 0 0 19px 0 #0000001c;
}
.tools .content .section .con h3 {
  font-size: 25px;
  opacity: 0.7;
  margin: 30px 0;
}
.tools .content .section .con p {
  line-height: 1.8;
  font-size: 14px;
}
@media (max-width: 768px) {
  .tools .content .section,
  .stories .content .cont {
    flex-wrap: wrap;
  }
  .tools .content .section .con {
    margin-left: auto;
    margin-right: auto;
  }
}
/* end tools  */
/* start get your program  */
.gt_y_prgm {
  margin-top: 100px;
  position: relative;
}
.gt_y_prgm img {
  min-width: 100%;
  filter: brightness(0.5);
}
.gt_y_prgm .parent {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.gt_y_prgm .parent .content h1 {
  color: white;
  letter-spacing: 1.3px;
  word-spacing: 2px;
  font-size: 27px;
}
.gt_y_prgm .parent .content hr {
  border: none;
  background-color: var(--main_color);
  height: 4px;
  width: 400px;
  margin: 5px auto 10px;
  border-radius: 3px;
}
.gt_y_prgm .parent .content p {
  color: white;
  letter-spacing: 1.2px;
  font-size: 15px;
  line-height: 1.8;
}
.gt_y_prgm .parent .content button {
  width: auto;
  margin-top: 20px;
}
.gt_y_prgm .parent .content button a {
  padding: 15px 60px;
}
@media (max-width: 768px) {
  .gt_y_prgm .parent .content h1 {
    line-height: 1.6;
  }
}
/* end get your program  */
/* start storie  */
.stories {
  margin-top: 80px;
  text-align: center;
}
.stories .content .cont .con {
  padding: 30px;
  box-shadow: 0 0 20px 0 rgb(5 5 5 / 9%);
  border: 1px solid #00000012;
  margin: 20px 10px;
  border-radius: 3px;
  text-align: start;
}
.stories .content .cont .con img {
  width: 100%;
}
.stories .content .cont .con h3 {
  margin: 15px 0 10px;
  font-size: 25px;
  letter-spacing: 0.1px;
  font-weight: bold;
}
.stories .content .cont .con p {
  font-size: 14px;
  letter-spacing: 1px;
  word-spacing: 1.5px;
  line-height: 1.3;
  opacity: 0.6;
}
.stories .content .cont .con .name {
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.stories .content .cont .con .name hr {
  width: 14px;
  height: 2px;
  border-radius: 2px;
  margin-right: 6px;
  border: none;
  background-color: var(--main_color);
}
.stories .content .cont .con .name span {
  font-size: 16px;
  color: var(--main_color);
  font-weight: 600;
}
@media (max-width: 768px) {
  .stories .content {
    text-align: center;
  }
}
/* end storie */
/* start slider  */
.slider {
  margin-top: 80px;
}
.slider .content {
  height: 172px;
  will-change: background-position-x;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 420s;
}
.slider .content1 {
  background: url(https://www.beachbodyondemand.com/assets/programs-rail-bg.b6633a1a6d03d885.jpg.jpg)
    0% 0% / cover repeat-x;
  animation-name: slider1;
}
.slider .content2 {
  background: url(https://www.beachbodyondemand.com/assets/programs-rail-bg.b6633a1a6d03d885.jpg.jpg)
    0% 0% / cover repeat-x;
  animation-name: slider2;
}
@keyframes slider1 {
  0% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: calc(-100% + 100vw);
  }
}
@keyframes slider2 {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: calc(133% - 100vw);
  }
}
/* end slider  */
/* start footer  */
footer {
  background-color: var(--main_color);
}
footer .container {
  text-align: center;
  margin-top: 60px;
  padding: 1px 0 12px;
}
footer h1 a {
  display: block;
  color: white;
  text-decoration: none;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2.5px;
  transition: 0.1s;
}
footer .container h1 a:hover {
  transform: scale(1);
  text-shadow: 0px 0px 3px black;
}
footer .social {
  word-spacing: 17px;
  font-size: 27px;
}
footer .social a {
  text-decoration: none;
}
footer .social a i {
  transition: 0.2s;
  color: #3bda57;
}
footer .social a:first-child:hover i {
  color: white;
}
/* Start Designer */
.topfooter {
  background-color: #191923;
}
.topfooter .container {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}
.topfooter .container span {
  font-size: 12px;
  color: white;
  letter-spacing: 1.6px;
  word-spacing: 2px;
}
.topfooter .container span:first-child {
  order: 2;
  flex-grow: 1;
  text-align: center;
}
.topfooter .container span:last-child {
  order: 1;
  padding-left: 10px;
}
.topfooter .container span a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: 0.5s;
}
.topfooter .container span a:hover {
  color: var(--black-color);
}
@media (max-width: 768px) {
  .topfooter .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
  }
  .topfooter .container span:first-child {
    order: 1;
  }
}
/*End Designer */
