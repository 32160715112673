.content-list .allList li {
  display: flex;
  align-items: center;
  gap: 10px 20px;
  background-color: white;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  min-width: fit-content;
  margin-bottom: 10px;
  border: 1px solid #3333;
}
.content-list .allList li .foto {
  min-width: 60px;
  min-height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #3333;
  border-radius: 50%;
}
.content-list .allList li .foto + div {
  flex-grow: 1;
}
.content-list .allList li .foto i {
  font-size: 31px;
  color: #fbfbfb;
}
.content-list .allList li .info-1,
.content-list .allList li .info-2 {
  display: flex;
  justify-content: space-between;
  padding: 9px;
  align-items: center;
}
.content-list .allList li .date {
  color: #889;
  font-weight: 400;
  font-size: 13px;
}
.content-list .allList li .email {
  font-style: italic;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #555;
}
.content-list .allList li a {
  text-align: center;
  letter-spacing: 0.2px;
  padding: 6px 7px;
  background-color: #62a2cd;
  color: white;
  border-radius: 4px;
}
.content-list .allList li .sendTheProgram {
  display: flex;
  align-items: center;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.content-list .allList li .sendTheProgram label {
  padding: 9px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-right: 7px;
  cursor: pointer;
}
.content-list .allList li .sendTheProgram input {
  margin-right: 5px;
}
@media (max-width: 825px) {
  .content-list .allList li .info-2 {
    flex-wrap: wrap;
  }
  .content-list .allList li .info-2 .email {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .content-list .allList li .info-2 .date,
  .content-list .allList li .info-2 a {
    width: 50%;
  }
}
@media (max-width: 476px) {
  .content-list .allList li .info-1,
  .content-list .allList li .info-2 {
    flex-direction: column;
    align-items: center;
  }
  .content-list .allList li .name {
    margin-bottom: 10px;
  }
  .content-list .allList li .info-2 .date,
  .content-list .allList li .info-2 a {
    width: auto;
    margin-bottom: 10px;
  }
}
