.loading .container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: none;
  min-height: 100%;
  z-index: 100;
  opacity: 0.93;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.loading .container .content {
  font-size: 22px;
  color: var(--main_color);
  animation: creatingProAnim 1s infinite linear;
}
