@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "Lato", sans-serif;
}
.dashboard {
  display: flex;
}
.dashboard .sideBar {
  width: 17%;
  min-width: 200px;
  padding: 22px;
  border-right: 1px solid #3333;
  min-height: 100vh;
}
.dashboard .sideBar .logo {
  font-size: 22px;
  letter-spacing: 0.9px;
  font-weight: bold;
  margin: 10px 10px 60px;
}
.dashboard .sideBar ol li a {
  padding: 9px 12px;
  border-radius: 5px;
  display: block;
  font-size: 17px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  color: #3333339c;
}
.dashboard .sideBar ol li a:hover {
  color: var(--main_color);
}

.dashboard .sideBar ol li a.active {
  background-color: var(--main_color);
  color: white;
}
.dashboard .sideBar ol li a.active {
  color: white;
}
.dashboard .sideBar ol li a i {
  width: 20px;
  margin-right: 9px;
  font-size: 15px;
}
.dashboard .content {
  width: 83%;
  background-color: #60548512;
}
.dashboard .content .dashHeader {
  padding: 20px 26px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3333;
  background-color: white;
}
.dashboard .content .dashHeader .logo {
  letter-spacing: 0.7px;
  font-size: 24px;
}
.dashboard .content .dashHeader .icons {
  display: flex;
  gap: 10px;
}
.dashboard .content .dashHeader .icons a {
  border: 1px solid #3333;
  background-color: #3333330f;
  width: 31px;
  height: 32px;
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
}
.dashboard .content .dashHeader .icons a:hover {
  background-color: var(--main_color);
  color: white;
}
.dashboard .content main {
  padding: 20px 26px;
}

.dashboard .content main .topButtons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard .content main .topButtons .oneButton {
  color: white;
  width: calc(calc(100% / 3) - calc(40px / 3));
  padding: 20px;
  border-radius: 11px;
  min-width: 200px;
}
.dashboard .content main .topButtons .oneButton .top {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.6px;
}
.dashboard .content main .topButtons .oneButton i {
  margin-right: 5px;
}
.dashboard .content main .topButtons .oneButton h1 {
  margin: 15px 0;
  font-size: 35px;
  font-weight: 500;
}
.dashboard .content main .topButtons .oneButton p {
  letter-spacing: 0.6px;
  font-size: 15px;
}
.dashboard ol.filter {
  list-style: none;
  max-width: 700px;
  width: 100%;
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.dashboard ol.filter li {
  border: 1px solid #cdcdcd;
  background-color: #a5a5a5;
  min-width: 120px;
  padding: 4px 10px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.4px;
  border-radius: 2px;
  cursor: pointer;
}
.dashboard ol.filter li.active {
  border: var(--main_color);
  background-color: var(--main_color);
}
@media (max-width: 768px) {
  .dashboard .sideBar {
    display: none;
  }
  .dashboard .content {
    width: 100%;
  }
  .dashboard .content main {
    padding: 10px 5px;
  }
}
